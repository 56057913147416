@supports (font-variation-settings: normal) {
  :root {
    font-family: 'Hanken Grotesk';
    --toastify-color-light: #01602d;
    --toastify-text-color-light: #ffffff;
    --toastify-color-success: #02e56a;
    --toastify-color-info: #e57402;
  }
}

.Toastify__toast {
  border-radius: 10px;
  padding: 0;
}
.Toastify__toast.Toastify__toast--warning {
  background-color: #494721 !important;
}
.Toastify__toast.Toastify__toast--error {
  background-color: #472124 !important;
}
.Toastify__toast.Toastify__toast--info {
  background-color: #262626 !important;
}

.Toastify__toast-body {
  padding: 0;
  display: grid;
  grid-template-columns: 64px auto;
}

.Toastify__toast-body .Toastify__toast-icon {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 80px;
  height: 100%;
}

.Toastify__toast--success .Toastify__toast-body .Toastify__toast-icon {
  background-image: url(../img/toast/bg-icon-success.png);
}

.Toastify__toast--warning .Toastify__toast-body .Toastify__toast-icon {
  background-image: url(../img/toast/bg-icon-warning.png);
}

.Toastify__toast--error .Toastify__toast-body .Toastify__toast-icon {
  background-image: url(../img/toast/bg-icon-error.png);
}

.Toastify__toast--info .Toastify__toast-body .Toastify__toast-icon {
  background-image: url(../img/toast/bg-icon-info.png);
}

.Toastify__progress-bar {
  height: 2px;
}

.Toastify__toast-icon {
  width: 36px;
  height: 36px;
}

@font-face {
  font-family: 'Digital';
  src: url(../fonts/digital-7.regular.ttf);
}

#root {
  overflow-x: hidden;
  overflow-y: auto;
}

select {
  background-position: right 0.125rem center;
  background-size: 1.25em 1.25em;
}

ul {
  list-style-type: none;
  margin-bottom: 0;
}

label {
  font-size: 13px;
  margin-bottom: 0.125rem;
  align-self: center;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-wrapper
  .react-datepicker__input-container
  input[type='text'] {
  background-image: url(../img/icon/icon_calendar.png);
  background-repeat: no-repeat;
  background-position: right 15px center;
  background-color: var(--color-surface-soild-secondary);
}

a.editable-text:not([href]) {
  color: #007bff;
  cursor: pointer;
}

a.editable-text:not([href]):hover {
  text-decoration: underline;
}

@keyframes blink {
  0% {
    background: none;
  }

  25% {
    background: #ffc0cb;
  }

  50% {
    background: none;
  }

  75% {
    background: #ffc0cb;
  }

  100% {
    background: none;
  }
}

.blink {
  -webkit-animation-direction: normal;
  -webkit-animation-duration: 3.5s;
  -webkit-animation-iteration-count: 8;
  -webkit-animation-name: blink;
  -webkit-animation-timing-function: ease;
}

.text-underline {
  text-decoration: underline !important;
}

.popover-header {
  padding: 0.25rem 0.5rem;
  font-size: 13px;
}

.popover-body {
  padding: 0.25rem 0.5rem;
}

/**------------------------------------------------------------
* table
---------------------------------------------------------------*/

.table {
  margin-bottom: 0;
  border-collapse: collapse;
}

.table th,
.table thead th {
  vertical-align: middle;
  letter-spacing: 0.5px;
}

.table td,
.table th {
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-size: 12px;
  vertical-align: middle;
  word-break: break-word;
}

.table tfoot td {
  font-weight: 600;
  background: var(--color-text-title) !important;
}

.table.table-bordered thead {
  border-radius: 24px;
  background-color: var(--color-bg-tbl-thead);
  box-shadow: 0px 0px 8px 0px #7676762b inset;
}

.table.table-bordered th {
  padding: 0 4px;
  height: 28px;
  font-weight: 500;
  text-align: right;
}

.table.table-bordered td {
  padding: 4px;
  height: 32px;
  text-align: right;
}

.table.table-bordered tr:first-of-type th:first-of-type,
.table.table-bordered tr td:first-of-type {
  text-align: left !important;
}

.table.table-bordered tr {
  text-align: left !important;
}

.table.table-bordered tr:nth-child(even) td {
  background: var(--color-bg-tbl-even);
}

.table-bordered tr:nth-child(even) td.high-light,
.table-bordered tr:nth-child(odd) td.high-light,
.table-bordered tr th.high-light {
  background: var(--color-bg-tbl-light);
}

.table-bordered tr td.high-light.bd-left,
.table-bordered tr td.high-light.bd-left,
.table-bordered tr th.high-light.bd-left {
  border-left: 1px solid var(--color-border-tbl);
}

.table-bordered tr td.high-light.bd-right,
.table-bordered tr td.high-light.bd-right,
.table-bordered tr th.high-light.bd-right {
  border-right: 1px solid var(--color-border-tbl);
}

.table-bordered tr td.high-light.bd-light,
.table-bordered tr td.high-light.bd-light,
.table-bordered tr th.high-light.bd-light {
  border-left: 1px solid var(--color-border-tbl);
  border-right: 1px solid var(--color-border-tbl);
}

.table.table-bordered tr:nth-child(even):hover td,
.table.table-bordered tr:nth-child(odd):hover td,
.table-bordered tr:nth-child(even):hover td.high-light,
.table-bordered tr:nth-child(odd):hover td.high-light,
.table.table-bordered tr.bg-active td,
.table.table-bordered tr.bg-active td,
.table-bordered tr.bg-active td.high-light,
.table-bordered tr.bg-active td.high-light {
  background-color: var(--color-bg-tbl-td-hover);
}

/* .table-bordered path {
  fill: var(--color-img-tbl);
} */

.table-bordered tr.total td {
  background: #f5f5f5;
  color: #fe5353;
  font-weight: 500;
  font-size: 14px;
}

.table.table-borderedPT th {
  padding: 0 8px;
  height: 28px;
  font-weight: 500;
  text-align: right;
}

.table.table-borderedPT td {
  padding: 0 8px;
  height: 28px;
  text-align: right;
}

.table.table-borderedPT th {
  background-color: var(--color-bg-tbl-theadPT);
}

.table.table-borderedPT tr:first-of-type th:first-of-type {
  border-top-left-radius: 16px;
}

.table.table-borderedPT tr:first-of-type th:last-of-type {
  border-top-right-radius: 16px;
}

.table.table-borderedPT tr:last-of-type th:first-of-type {
  border-bottom-left-radius: 16px;
}

.table.table-borderedPT tr:last-of-type th:last-of-type {
  border-bottom-right-radius: 16px;
}

.table.table-agree tr:first-of-type th,
.table.table-agree tr td:first-of-type {
  text-align: center !important;
}

.table.table-agree tr {
  text-align: left !important;
}

/* table-nobordered  */

.table-nobordered tr th {
  padding: 0 8px;
  background: var(--color-bg-tbl-th);
  font-size: 12px;
  font-weight: 500;
  color: var(--color-text-body);
  height: 40px;
}
.table-nobordered tr td {
  background: transparent;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  height: 40px;
  padding: 0 8px;
}

/* table-borderless */

.table-borderless th {
  background: var(--color-bg-tbl-th);
  color: var(--color-text-muted);
  box-sizing: border-box;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  white-space: nowrap;
  padding: 6px 8px;
  border-bottom: 0.5px solid var(--color-border-td);
}

.table.table-borderless td {
  padding: 6px 8px;
  font-size: 12px;
  line-height: 16px;
  border-bottom: 0.5px solid var(--color-border-td);
  color: var(--color-text-4);
}

/* table-index */

.table-index tr th {
  padding: 0 8px;
  background: var(--color-bg-tbl-theadPT);
  font-size: 12px;
  font-weight: 500;
  color: var(--color-text-subdued);
  height: 32px;
}

.table-index tr th:first-of-type,
.table-index tr th:last-of-type {
  padding: 0;
  background: var(--color-bg-index);
}

.table-index tr th:first-of-type span,
.table-index tr th:last-of-type span {
  width: 100%;
  height: 100%;
  padding: 0 8px;
  background: var(--color-bg-tbl-theadPT);
  display: flex;
  align-items: center;
}

.table-index tr td {
  background: transparent;
  font-size: 12px;
  font-weight: 500;
  height: 24px;
  padding: 0 8px;
}

.table-index tr:nth-child(even) td {
  background: var(--color-bg-tbl-theadPT);
}

.table-index tr td:first-of-type {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.table-index tr td:last-of-type {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

/* table-sell  */

.table-sell tr th {
  padding: 0 8px;
  font-size: 12px;
  font-weight: 500;
  height: 32px;
}
.table-sell tr td {
  font-size: 14px;
  line-height: 20px;
  height: 40px;
  padding: 0 8px;
}

.tbl-sub {
  font-size: 11px;
}

@media (max-width: 450px) {
  .table td,
  .table th {
    padding: 2px;
    font-size: 12px;
  }

  .tbl-sub {
    font-size: 8px;
  }
}

/**------------------------------------------------------------
* color
---------------------------------------------------------------*/
.i,
.g,
.green {
  color: var(--color-text-up) !important;
}

.auth-messages,
.d,
.red {
  color: var(--color-text-down) !important;
}

.e,
.r,
.y,
.yellow {
  color: var(--color-text-ref) !important;
}

.f,
.violet {
  color: var(--color-text-floor) !important;
}

.c,
.violet {
  color: var(--color-text-ceil) !important;
}

.d.active div,
.red.active div {
  background: var(--color-text-down) !important;
  color: var(--color-text-title) !important;
  border-radius: 6px;
}

.i.active div {
  background: var(--color-text-up) !important;
  color: var(--color-mint-900) !important;
  border-radius: 6px;
}

.r.active div,
.e.active div {
  background: var(--color-text-ref) !important;
  color: var(--color-mint-900) !important;
  border-radius: 6px;
}

.f.active div {
  background: var(--color-text-floor) !important;
  color: var(--color-mint-900) !important;
  border-radius: 6px;
}

.c.active div {
  background: var(--color-text-ceil) !important;
  color: var(--color-mint-900) !important;
  border-radius: 6px;
}

.lot.active div {
  background: var(--color-bg) !important;
  border-radius: 6px;
}

.index {
  color: var(--color-text-3) !important;
}

.unit {
  color: var(--color-text-subdued) !important;
}

/**------------------------------------------------------------
* header
---------------------------------------------------------------*/
/* .form-control {
  padding: 5px 8px;
  border-color: rgb(198, 203, 210);
} */

.form-control:focus {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-analysis:focus {
  box-shadow: 0 0 0 0.2rem var(--color-natural-3);
}

.app-header {
  height: 56px;
  font-size: 16px;
  font-weight: normal;
  color: var(--color-text-title);
  background: var(--color-bg-header);
  padding: 8px 16px;
}

.app-header-container {
  background: #262d35;
}

.app-header.sticky {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
}

.app-header svg.radio path:first-child {
  fill: var(--color-radio-header);
}

.app-header svg.radio.uncheck path:last-child {
  fill: #00923f;
}

.app-header .btn-login {
  background-color: var(--color-bg-btn-login);
  color: var(--color-text-btn-login);
}

.dropdown {
  position: relative;
}

.dropdown-menu.show {
  position: absolute;
  top: 36px;
  z-index: 1;
  min-width: 145px;
  max-width: 150px;
  background-color: #ffffff;
  box-shadow:
    rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  border-radius: 2px;
}

.dropdown-menu.show ul {
  padding-inline-start: 0;
  font-size: 13px;
}

.dropdown-menu.show ul li {
  padding: 5px 10px;
  cursor: pointer;
  color: #000;
}

.dropdown-menu.show ul li:hover {
  background: #f3f9ff;
}

.dropdown-menu.show ul li.active {
  background: #4d7496;
  color: #fff;
}

/**------------------------------------------------------------
* body
---------------------------------------------------------------*/
.app-body {
  display: block;
}

main.main {
  display: flex;
  flex-direction: column;
  position: relative;
  background-color: var(--color-bg-main);
  /* border-top-left-radius: 32px;
  border-top-right-radius: 32px; */
  height: calc(100vh - 56px);
}

.card {
  box-shadow: 0 4px 12px -4px rgba(0, 0, 0, 0.19);
  border: none;
}

.card .card-header {
  background: transparent;
  border-color: #e8eaeb;
  padding: 0.5rem 1.25rem;
  font-weight: 500;
  font-size: 14px;
}

.card .card-header svg path {
  stroke: #ffa31a;
}

.card .card-body {
  padding: 0.5rem;
  font-size: 14px;
}

.rotate {
  animation: rotation 2s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.btn-search {
  width: 120px;
  height: 28px;
}

.tbl-group-button {
  gap: 5px;
}

.tbl-group-button a {
  background: #f3f3f3;
  border-radius: 100px;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tbl-group-button a svg {
  max-height: 13px;
  max-width: 13px;
}

.tbl-group-button a svg path {
  stroke: var(--color-bg-primary);
}

.custom-ui-confirm h1 {
  font-size: 35px;
  font-weight: 500;
}

.upload-file {
  position: relative;
}

.upload-file .file {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  top: 0px;
  left: 0px;
  opacity: 0;
  cursor: pointer;
}

.btn {
  display: inline-flex;
  justify-content: center;
  column-gap: 7px;
  align-items: center;
  border-radius: 6px;
  font-weight: 500;
}

.btn i {
  font-size: 16px;
}

/**------------------------------------------------------------
*!perfect scrollbar
---------------------------------------------------------------*/

.ps__rail-x {
  height: 6px;
}

.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
  background-color: var(--color-thumb) !important;
  height: 6px;
}

.ps .ps__rail-x:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-x.ps--clicking {
  background-color: var(--color-surface-soild-secondary) !important;
  opacity: 0.9;
  height: 6px;
}

.ps__thumb-x {
  height: 6px;
  bottom: 0;
}

.ps__thumb-y,
.ps__rail-y {
  width: 0px;
}

.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: var(--color-thumb) !important;
  width: 6px;
}

.ps .ps__rail-y:hover,
.ps .ps__rail-y:focus,
.ps .ps__rail-y.ps--clicking {
  background-color: var(--color-surface-soild-secondary) !important;
  opacity: 0.9;
  width: 6px;
}

.table-priceboard .ps__rail-y {
  opacity: 0.9 !important;
}

.table-priceboard .ps__thumb-y {
  width: 6px !important;
  opacity: 0.9 !important;
  right: 0 !important;
}

.panel-depth-market {
  border-top: 1px solid #020810;
  max-height: 396px;
}

.panel-depth-market ul li {
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 4px;
  padding: 6px 8px;
}

.panel-asset-footer ul {
  border: 1px solid var(--color-natural-1);
}

.panel-asset-footer ul li {
  display: flex;
  justify-content: space-between;
  padding: 6px 8px;
}

.panel-asset-footer ul li.tbl-header {
  background-color: var(--color-natural-1);
}

.panel-asset-footer ul li:nth-child(odd):not(.tbl-header),
.panel-depth-market ul li:nth-child(odd):not(.tbl-header) {
  background-color: var(--color-bg-li-light);
}

.panel-depth-market ul li span {
  align-self: center;
}

.progress-bar {
  display: flex;
}

.progress-bar.has-unknown {
  background-color: var(--color-border);
}

.progress-bar .step-1 {
  border-radius: 2px 0 0 2px;
}

.progress-bar .step-2 {
  border-radius: 0 2px 2px 0;
}

.select-list-trading li {
  padding: 8px 0;
  font-size: small;
  position: relative;
  cursor: pointer;
  user-select: none;
}

.select-list-trading li:not(:last-of-type) {
  border-bottom: 1px solid var(--color-border-3);
}

.select-list-trading li.selected {
  color: var(--color-vendor-from);
}

.select-cus li.selected {
  color: var(--color-text-oncolor-green);
}

.text-input-trading {
  box-shadow: none;
  outline: none;
}

.text-input-trading:active,
.text-input-trading:focus {
  box-shadow: none;
  outline: none;
}

.text-skin-active svg path {
  fill: var(--color-text-6);
}

svg.text-skin-placeholder path {
  fill: var(--color-thumb);
}

.cl-border-var {
  border-color: var(--color-border);
}

ul.list-timeline li {
  position: relative;
}

ul.list-timeline li:not(:first-of-type)::before {
  content: '';
  position: absolute;
  top: -20px;
  left: 10px;
  width: 1px;
  height: 40px;
  background-color: var(--color-natural-4);
}

/* index-chart */

.green.bg-active,
.i.bg-active {
  background: var(--color-text-up) !important;
  color: var(--color-text-title) !important;
}

.red.bg-active,
.d.bg-active {
  background: var(--color-text-down) !important;
  color: var(--color-text-title) !important;
}

.r.bg-active {
  background: var(--color-text-ref) !important;
  color: var(--color-text-title) !important;
}

.form-add {
  width: 100%;
  height: 32px;
  padding: 6px;
  border-radius: 2px;
  background-color: var(--color-natural-1);
  color: var(--color-text-nav);
  text-transform: uppercase;
  border: 0;
  box-sizing: border-box;
}

.bg-skin-card2 .form-add {
  background-color: var(--color-bg-card2);
}

.form-add:focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.form-add::placeholder,
.form-search .form-control::placeholder {
  font-size: 12px;
  color: var(--color-text-placeholder);
  text-transform: initial !important;
}

/* price-table */
.slidable .active div {
  background: var(--color-bg) !important;
  color: var(--color-text-white) !important;
  border-radius: 8px;
}

table tr td > .slidable,
table tr th > .slidable {
  box-sizing: border-box;
  left: 0;
  padding-left: 0;
  padding-right: 0;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: inline-flex;
  align-items: center;
}

table tr td > .slidable > div.cell-1-2,
table tr th > .slidable > div.cell-1-2 {
  width: 50%;
  height: 100%;
  padding: 4px;
  position: relative;
  display: flex;
  align-items: center;
}

table tr td > .slidable > div.cell-1-1,
table tr th > .slidable > div.cell-1-1 {
  width: 100%;
  padding: 4px;
}

.table tr td .act-del {
  position: absolute;
  right: 2px;
  top: 8px;
  display: none;
  cursor: pointer;
  width: 15px;
  height: 12px;
}

.table tr:hover td .act-del {
  display: block;
}

/* table.table-watchlist tr td .act-star {
  display: none;
}

table.table-watchlist tr:hover td .act-star {
  display: block;
} */

.icon-sort-header {
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
}

.tbl-toggle-left {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  left: 0;
  color: var(--color-img-tbl);
  font-weight: 500;
}

.tbl-toggle-right {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  right: 0;
  color: var(--color-img-tbl);
  font-weight: 500;
}

/**------------------------------------------------------------
* stock detail
---------------------------------------------------------------*/
span.mc {
  width: 50px;
  height: 18px;
  border-radius: 4px;
  border: solid 1px #999999;
}

.detail-search .form-control {
  width: 120px;
  height: 26px;
  font-size: 13px;
  border-radius: 3px;
  background-color: #f3f3f3;
  border: solid 1px #f2f4f6;
  padding-right: 25px;
}

.table .rtl .progress {
  justify-content: flex-end;
}

.progress-bar {
  border-radius: 2px;
}

.table .progress .bg-info {
  background: #60a8ea !important;
  border-radius: 1px;
  height: 100%;
}

.table .progress .bg-danger,
.table .progress .bg-d {
  background: rgba(240, 161, 182, 0.3);
  border-radius: 1px;
  height: 100%;
}

.table .progress .bg-r {
  background: rgba(120, 112, 41, 0.3);
  border-radius: 1px;
  height: 100%;
}

.table .progress .bg-c {
  background: rgba(120, 64, 152, 0.3);
  border-radius: 1px;
  height: 100%;
}

.table .progress .bg-f {
  background: rgba(10, 131, 138, 0.3);
  border-radius: 1px;
  height: 100%;
}

.table .progress .bg-i {
  background: rgba(88, 160, 112, 0.3);
  border-radius: 1px;
  height: 100%;
}

ul.list-price-info li {
  padding: 2px 5px;
  border-bottom: 0.5px solid #cccccc;
}

.tab-pane {
  width: 100%;
  height: 100%;
}

.table .progress {
  background-color: transparent;
  padding: 2px 0;
  height: 22px;
  border-radius: 0;
}

.table td.rtl .title-row {
  left: 3px;
  position: absolute;
  top: 2px;
  padding: 2px 5px;
  background: #dddddd;
  border-radius: 2px;
  width: 16.75px;
  text-align: center;
}

.table .rtl .progress-bar {
  margin-left: auto;
}

.table td.rtl .per-vol {
  position: absolute;
  font-size: 12px;
  top: 4px;
  left: 0.75rem;
}

.table td.rtl .per-price {
  position: absolute;
  font-size: 12px;
  right: 8px;
  top: 4px;
}

.table td.rtr .title-row {
  right: 3px;
  position: absolute;
  top: 2px;
  padding: 2px 5px;
  background: #dddddd;
  border-radius: 2px;
  width: 16.75px;
  text-align: center;
}

.table td.rtr .per-vol {
  position: absolute;
  font-size: 12px;
  top: 4px;
  right: 22px;
}

.table td.rtr .per-price {
  position: absolute;
  font-size: 12px;
  top: 4px;
  left: 2px;
}

/* order */
.price-advance button,
.vol-advance button {
  font-size: 13px;
  padding: 2px 8px;
  border: 0.5px solid #4e4d62;
  border-radius: 4px;
}

.price-advance button:hover,
.vol-advance button:hover {
  background-color: #17a2b8;
}

.form-trade svg.active path {
  stroke: #f0b90b;
}

ul.list-vendor li.active,
ul.list-vendor li:hover {
  background: linear-gradient(
    180deg,
    var(--color-vendor-from) -10%,
    var(--color-vendor-to) 105%
  );
}

.form-order .form-control {
  background-color: var(--color-bg-input);
  border-color: var(--color-bd-input);
  height: 30px;
  border-radius: 2px;
}

.cart-action hr {
  border-top: 1px solid #4e4d62;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.form-gr-trade {
  background: var(--acc-info-lst-gr-odd);
  border: 0.5px solid #4e4d62;
  border-radius: 5px;
}

.form-gr-trade .form-control {
  border: none !important;
  overflow: hidden;
  padding: 5px 8px 5px 8px;
  line-height: 16px;
  background: transparent;
  font-size: 12px;
}

.btn-paging:active,
.btn-paging:focus,
.form-gr-trade .form-control:active,
.form-gr-trade .form-control:focus {
  outline: none;
  box-shadow: none;
}

.form-gr-trade .btn-minus {
  /* background: var(--color-bg-trade); */
  background: transparent;
  /* border-right: 0.5px solid var(--color-border-trade); */
  border-radius: 5px 0px 0px 5px;
  padding: 2px 10px;
  display: flex;
  justify-content: center;
}

.form-gr-trade .btn-plus {
  /* background: var(--color-bg-trade); */
  background: transparent;
  /* border-left: 0.5px solid var(--color-border-trade); */
  border-radius: 0px 5px 5px 0px;
  padding: 2px 10px;
  display: flex;
  justify-content: center;
}

.form-gr-trade .btn-plus svg,
.form-gr-trade .btn-minus svg {
  color: var(--color-img-tbl);
  font-size: 12px;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.form-gr-trade {
  background: var(--acc-info-lst-gr-odd);
  border: 1px solid #393939;
  border-radius: 1.5rem;
  padding: 5px;
}

.form-gr-trade .btn-minus-cus {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  border-radius: 1.5rem;
  border: 1px solid #393939;
  background-color: var(--color-natural-4);
}

.form-gr-trade .btn-plus-cus {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;
  border-radius: 1.5rem;
  border: 1px solid #393939;
  background-color: var(--color-natural-4);
}

.form-gr-trade .btn-minus-cus:hover,
.form-gr-trade .btn-plus-cus:hover {
  background-color: var(--color-natural-2);
}

.form-gr-trade .btn-plus-cus svg,
.form-gr-trade .btn-minus-cus svg {
  color: var(--color-img-tbl);
  font-size: 12px;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.form-gr-trade-order {
  background: var(--acc-info-lst-gr-odd);
  border-radius: 1.5rem;
  padding: 5px;
}

.form-gr-trade-order .btn-minus-cus {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 1.5rem;
  border: 1px solid #393939;
}

.form-gr-trade-order .btn-plus-cus {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 1.5rem;
  border: 1px solid #393939;
}

.form-gr-trade-order .btn-minus-cus:hover,
.form-gr-trade-order .btn-plus-cus:hover {
  background-color: var(--color-natural-2);
}

.form-gr-trade-order .btn-plus-cus svg,
.form-gr-trade-order .btn-minus-cus svg {
  color: var(--color-img-tbl);
  font-size: 12px;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.form-gr-trade-order .form-control {
  border: none !important;
  overflow: hidden;
  padding: 5px 8px 5px 8px;
  background: transparent;
}

.form-gr-trade-order .form-control:active,
.form-gr-trade-order .form-control:focus {
  outline: none;
  box-shadow: none;
}
/* checkbox */

.container-checkbox {
  display: inline-block;
  position: relative;
  cursor: pointer;
  font-size: 14px;
  user-select: none;
  -webkit-user-select: none;
  /* Chrome/Safari */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* IE10+ */
  margin: 0;
  margin-right: 10px;
  white-space: nowrap;
  padding-left: 20px;
}

.container-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.check_mark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
  background: var(--cl-nav-link);
  border-radius: 4px;
  border: 1px solid var(--color-border-2);
}

.container-checkbox:hover input ~ .check_mark {
  border-color: var(--color-vendor-active);
}

.check_mark::after {
  content: '';
  position: absolute;
  display: none;
}

.container-checkbox .check_mark::after {
  top: 45%;
  left: 50%;
  width: 5px;
  height: 10px;
  border: solid #27272d;
  border-width: 0 1px 1px 0;
  transform: translate(-50%, -50%) rotate(45deg);
}

.container-checkbox input:checked ~ .check_mark {
  background: var(--color-vendor-active);
  border-color: var(--color-vendor-active);
}

.container-checkbox input:checked ~ .check_mark::after {
  display: block;
}

/* radio */
.container-radio {
  display: inline-block;
  position: relative;
  cursor: pointer;
  font-size: 14px;
  user-select: none;
  padding-left: 28px;
  margin-bottom: 0px;
  font-size: 14px;
}

.container-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0;
  height: 20px;
  width: 20px;
  z-index: 10;
}

.container-radio input:checked ~ .checkmark::after {
  display: block;
}

.container-radio .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  background: transparent;
  border-radius: 50%;
  border: 1px solid;
  border-color: #3b3b3b;
}

.container-radio:hover .checkmark {
  opacity: 0.8;
}

.container-radio .checkmark:after {
  content: '';
  position: absolute;
  display: none;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: white;
  transform: translate(-50%, -50%) rotate(45deg);
}

.container-radio input:checked ~ .checkmark {
  border-color: var(--color-vendor-active);
  background: var(--color-vendor-active);
}

.container-radio:hover .checkmark {
  border-color: var(--color-vendor-active);
}

/**------------------------------------------------------------
* bước giá
---------------------------------------------------------------*/

.progress-total .progress {
  background: rgba(240, 161, 182, 0.3);
  opacity: 0.2;
  height: 20px;
  border-radius: 2px;
}

.progress-total .per-vol-sell,
.progress-total .per-vol-buy {
  position: absolute;
  top: 2px;
  color: var(--color-text-half-white);
  font-size: 12px;
}

.progress-total .per-vol-buy {
  left: 10px;
}

.progress-total .per-vol-sell {
  right: 10px;
}

.progress-total .bg-success,
.progress-total .bg-i {
  background: rgba(88, 160, 112, 1);
  height: 20px;
}

.tabs-cash a.active,
.tabs-cash span.active {
  color: var(--color-text-6);
  border-bottom: 1px solid var(--color-text-6);
}

/* đặt lệnh nhanh  */
.panel-trade {
  position: absolute;
  bottom: 0px;
  right: 0;
  height: calc(100vh - 60px);
  width: 460px;
  background-color: var(--color-bg-panel-trade);
  border-radius: 4px;
  padding: 12px;
  backdrop-filter: blur(7px);
  box-shadow: -5px -9px 19.700000762939453px 0px #000000a8;
  box-shadow: 1px -3px 5.099999904632568px 0px #ffffff21;
}

.grounded-radiants-form-order {
  position: relative;
  border-radius: 24px;
  background: linear-gradient(180deg, #7b57ff 0%, rgba(123, 87, 255, 0) 50%);
  background-clip: padding-box;
  padding: 2px;
  box-shadow: 0px 0px 12px 0px #7b57ffb2;
  margin: 0px 3px;
}

.grounded-radiants-form-order::after {
  position: absolute;
  top: -4px;
  bottom: -4px;
  left: -4px;
  right: -4px;
  background: linear-gradient(180deg, #7b57ff 0%, rgba(123, 87, 255, 0) 50%);
  content: '';
  z-index: -1;
  border-radius: 24px;
}

.grounded-radiants-form-trade {
  position: relative;
  border-radius: 24px;
  background: linear-gradient(180deg, #7b57ff 0%, rgba(123, 87, 255, 0) 50%);

  background-clip: padding-box;
  box-shadow: 0px 0px 12px 0px #7b57ffb2;
}

.grounded-radiants-form-trade::after {
  position: absolute;
  top: -2px;
  bottom: -2px;
  left: -2px;
  right: -2px;
  background: linear-gradient(180deg, #7b57ff 0%, rgba(123, 87, 255, 0) 50%);
  content: '';
  z-index: -1;
  border-radius: 24px;
}

.btn-type-trade {
  width: 98px;
  height: 26px;
  border-radius: 3px;
  font-size: 12px;
  padding: 4px;
}

.btn-type-trade.buy.active {
  background-color: var(--color-bg-buy);
  position: relative;
  border-top-right-radius: 1px;
  border-bottom-right-radius: 1px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-type-trade.buy.active::after,
.btn-type-trade.buy.active::before {
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -19px;
  border-top: 19px solid transparent;
  border-bottom: 19px solid transparent;
  border-left: 19px solid;
  right: -18px;
}

.btn-type-trade.buy.active::after {
  z-index: 2;
  border-left-color: var(--color-bg-buy);
}

.btn-type-trade.sell.active {
  background-color: var(--color-bg-sell);
  color: var(--color-text-title);
  position: relative;
  border-top-left-radius: 1px;
  border-bottom-left-radius: 1px;
}

.btn-type-trade.sell.active::after,
.btn-type-trade.sell.active::before {
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -19px;
  border-top: 19px solid transparent;
  border-bottom: 19px solid transparent;
  border-right: 19px solid;
  left: -18px;
}

.btn-type-trade.sell.active::after {
  z-index: 2;
  border-right-color: var(--color-bg-sell);
}

.a-type-trade {
  width: 98px;
  font-size: 12px;
  text-align: center;
  padding: 4px;
}

.input-order.input-symbol {
  display: block;
  color: #495057;
  width: 100%;
  padding: 4px 0.5rem;
  font-weight: 400;
  border-radius: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
}

.input-order.input-symbol:focus {
  color: #495057;
  background-color: #fff;
  box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  outline: 0 none;
}

/* .tooltip */

#tooltip-c {
  color: var(--color-text-half-white);
  background-color: var(--color-bg-tooltip);
  border: 0.5px solid var(--color-border-tooltip);
  border-radius: 4px;
  font-size: 12px;
  padding: 5px;
  z-index: 1000;
}

.tooltip::after {
  content: '';
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: -7px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid var(--color-border-tooltip);
}

.tooltip-1 {
  z-index: 1000;
  position: relative;
  color: var(--color-text-half-white);
  background-color: var(--color-bg-tooltip);
  border: 0.5px solid var(--color-border-tooltip);
  border-radius: 4px;
  font-size: 12px;
  padding: 8px;
  display: none;
}

.tooltip-1::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -7px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid var(--color-border-tooltip);
  transform: translate(-50%, 0);
}

.tooltip-div:hover .tooltip-1 {
  display: block;
}

.tooltip-2 {
  z-index: 1000;
  position: relative;
  color: #495057;
  background-color: #fff;
  border-radius: 4px;
  font-size: 12px;
  padding: 5px 15px;
}

.tooltip-2::after {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -7px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #fff;
}

.tooltip-2 button {
  background-color: var(--color-bg-primary);
  border-radius: 5px;
  padding: 5px;
}

.tooltip-2 button:hover {
  background-color: var(--color-text-floor);
}

ul.list-price-overview li {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 6px 0px;
}

#text-scroll-header {
  /* animation properties */
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);

  -moz-animation: scroll-text-animation 45s linear infinite;
  -webkit-animation: scroll-text-animation 45s linear infinite;
  animation: scroll-text-animation 45s linear infinite;
}

/* for Firefox */
@-moz-keyframes scroll-text-animation {
  from {
    -moz-transform: translateX(100%);
  }

  to {
    -moz-transform: translateX(-100%);
  }
}

/* for Chrome */
@-webkit-keyframes scroll-text-animation {
  from {
    -webkit-transform: translateX(100%);
  }

  to {
    -webkit-transform: translateX(-100%);
  }
}

@keyframes scroll-text-animation {
  from {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  to {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.form-control::placeholder {
  text-transform: capitalize;
  color: var(--color-text-placeholder);
}

.ordStt_PM {
  color: var(--color-stt-1);
}

.ordStt_PC,
.ordStt_P {
  color: var(--color-stt-2);
}

.ordStt_PMX,
.ordStt_PX {
  color: var(--color-stt-3);
}

.paging-type2 svg path {
  stroke: var(--color-text-3);
}

.paging-type2 .disabled svg path {
  stroke: var(--color-border-3);
}

.account-info {
  background-image: url('../img/bg/account-info.png');
  background-position: center;
  background-size: cover;
  height: 320px;
  width: 100%;
}

.card-index {
  background: var(--color-bg-index);
  padding: 8px 12px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
}

.card-index .text-skin-up svg path {
  fill: var(--color-text-up);
}

.card-index .text-skin-down svg path {
  fill: var(--color-text-down);
}

.bg-skin-badge-down svg path {
  fill: var(--color-text-down);
}

.bg-skin-badge-up svg path {
  fill: var(--color-text-up);
}

.header-time {
  font-family: 'Digital';
  background: var(--color-natural-3);
  color: var(--color-text-up);
  width: 124px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 4px;
  border-radius: 4px;
}

.text-linear-vcbs {
  background: linear-gradient(
    92.56deg,
    var(--color-vendor-from) 0.42%,
    var(--color-vendor-to) 102.36%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.btn-vendor {
  background: linear-gradient(
    92.56deg,
    var(--color-vendor-from) 0.42%,
    var(--color-vendor-to) 102.36%
  );
}

.indexSessionJ,
.indexSessionI,
.indexSessionK {
  background: rgba(0, 0, 0, 0.32);
}

.indexSessionP {
  background: linear-gradient(
    270deg,
    rgba(224, 148, 0, 0) 0%,
    rgba(224, 148, 0, 0.8) 100%
  );
}
.indexSessionA {
  background: linear-gradient(
    270deg,
    rgba(255, 0, 23, 0) 0%,
    rgba(255, 0, 23, 0.4) 100%
  );
}

.indexSessionO {
  background: linear-gradient(
    270deg,
    rgba(27, 185, 100, 0) 0%,
    rgba(27, 185, 100, 0.3) 100%
  );
}

.nextSessionA {
  background: linear-gradient(
    270deg,
    rgba(224, 148, 0, 0) 0%,
    rgba(224, 148, 0, 0.8) 100%
  );
}

.nextSessionO {
  background: linear-gradient(
    270deg,
    rgba(27, 185, 100, 0) 0%,
    rgba(27, 185, 100, 0.8) 100%
  );
}

.nextSessionI {
  background: linear-gradient(
    270deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
}

.nextSessionA {
  background: linear-gradient(
    270deg,
    rgba(255, 0, 23, 0) 0%,
    rgba(255, 0, 23, 0.8) 100%
  );
}

.nextSessionP {
  background: linear-gradient(
    270deg,
    rgba(224, 148, 0, 0) 0%,
    rgba(224, 148, 0, 0.8) 100%
  );
}

.indexSessionO .bg-skin-natural-4 {
  background-color: rgba(52, 200, 90, 1) !important;
}

.indexSessionJ .bg-skin-natural-4,
.indexSessionK .bg-skin-natural-4,
.indexSessionI .bg-skin-natural-4 {
  background: rgba(51, 51, 51, 1) !important;
}

.indexSessionP .bg-skin-natural-4 {
  background-color: rgba(244, 159, 59, 1) !important;
}
.indexSessionA .bg-skin-natural-4 {
  background-color: rgba(253, 59, 49, 1) !important;
}

.nextSessionI .bg-skin-natural-4,
.nextSessionK .bg-skin-natural-4,
.nextSessionP .bg-skin-natural-4,
.nextSessionO .bg-skin-natural-4,
.nextSessionA .bg-skin-natural-4 {
  background-color: rgba(255, 255, 255, 0.4) !important;
}

.st-header-item {
  border: 1px solid var(--color-border-1);
  cursor: pointer;
}

.st-header-item .st-header-close {
  display: none;
}

.st-header-item:hover .st-header-close {
  display: block;
  cursor: pointer;
}

.text-skin-hyperlink svg path {
  fill: var(--color-hyperlink);
}

svg.text-skin-text3 path {
  fill: var(--color-text-3);
  stroke: var(--color-text-3);
}
svg.text-skin-dark path {
  fill: var(--color-text-dark);
  stroke: var(--color-text-dark);
}
.divider {
  width: 0px;
  /* margin: 0 16px; */
  height: 24px;
  border-left: 1px solid var(--color-bg-divider);
}

/*bg custom*/
.bg-header-active {
  background: var(--color-surface-inverse-white);
}
.bg-btn-hover:hover {
  background: var(--color-white-alpha-200);
  border-radius: 12px;
  cursor: pointer;
}

.text-body {
  color: var(--color-text-body);
}

.text-title {
  color: var(--color-text-title);
}

.text-green {
  color: var(--color-text-oncolor-green);
}

.text-danger {
  color: var(--color-text-red);
}

.text-disabled {
  color: var(--color-text-disable);
}

.bg-soild-primary {
  background: var(--color-surface-soild-primary);
}

.bg-inverse {
  background: var(--color-surface-inverse-white);
}

.icon-white {
  color: var(--color-icon-white);
}

.icon-gray path {
  fill: var(--color-icon-gray);
}

.border-forcus {
  border: 1px solid var(--color-border-white);
}

.border-strong {
  border: 1px solid var(--color-border-strong);
}

.input-focus:focus {
  border: 1px solid var(--color-border-white);
}

/*btn cus */

.btn-plus {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 32px;
  border: 1px solid var(--color-border-strong);
}

.btn-plus svg {
  color: var(--color-icon-gray);
}

/*table custom*/
.table-cus th {
  border: none;
  background: var(--color-surface-soild-tertiary);
  padding: 0px 8px;
  height: 40px;
  font-size: 0.75rem; /* 12px */
  line-height: 1rem; /* 16px */
  color: var(--color-text-subdued);
  font-weight: unset;
  position: sticky;
  top: -2px;
  z-index: 1;
}

.table-cus th:last-child {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}

.table-cus th:first-child {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

.table-cus td {
  height: 48px;
  padding: 0 8px;
  font-size: 0.875rem; /* 14px */
  line-height: 1.25rem; /* 20px */
  font-weight: 500;
}

/* custom datepicker */
.react-datepicker {
  background: var(--color-surface-soild-primary);
  box-shadow: 0px 2px 16px 0px #00000014;
  border-radius: 12px;
  border: 1px solid var(--color-surface-soild-primary);
  padding-bottom: 8px;
  font-family: 'Hanken Grotesk' !important;
  user-select: none !important;
}

.react-datepicker__header,
.react-datepicker__day-names,
.react-datepicker__month {
  background: var(--color-surface-soild-primary);
  border: none;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: var(--color-text-body);
}

.react-datepicker__month {
  margin: 0;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 32px;
  background: var(--color-icon-oncolor-green);
  color: var(--color-mint-900);
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background: none;
}

.react-datepicker__month-container {
  font-size: 14px;
  line-height: 20px;
}

.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle::after {
  visibility: hidden !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  margin: 0px 6px;
  padding-top: 4px;
  width: 36px;
  height: 36px;
  cursor: pointer;
}

.react-datepicker__header:not(.react-datepicker__header--has-time-select) {
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}

.react-datepicker__month {
  border-radius: 12px;
}

.react-datepicker__day:hover {
  border-radius: 32px;
  background: var(--color-icon-oncolor-green);
  color: var(--color-mint-900);
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  color: var(--color-text-disable) !important;
  cursor: default !important;
}

.react-datepicker__day--disabled:hover {
  background: none !important;
}

/*btn radio check*/
/* The container */
.radio-check {
  display: block;
  position: relative;
  padding-left: 22px;
  cursor: pointer;
  font-size: 0.875rem; /* 14px */
  line-height: 1.25rem; /* 20px */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radio-check input {
  position: relative;
  left: -22px;
  top: -3px;
  opacity: 0;
  cursor: pointer;
  width: 20px;
  height: 20px;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 1px;
  left: 0;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  border: 1px solid var(--color-border-strong);
  background: transparent;
}

.checkmark-position {
  top: -6px !important;
}

/* On mouse-over, add a grey background color */
.radio-check:hover input ~ .checkmark {
  background-color: #00923f;
}

/* When the radio button is checked, add a green background */
.radio-check input:checked ~ .checkmark {
  background: var(--color-icon-oncolor-green);
}
.radio-check input:checked ~ .lable-i {
  color: var(--color-text-title);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-check input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio-check .checkmark:after {
  top: 4px;
  left: 4px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: white;
}

.card-cus:hover {
  background: var(--color-surface-soild-secondary);
  cursor: pointer;
}

.input-text-cus::placeholder {
  color: var(--color-text-subdued);
  font-size: 1rem; /* 16px */
  line-height: 1.5rem; /* 24px */
  font-weight: 400;
}

.input-text::placeholder {
  color: var(--color-text-subdued);
  font-size: 1rem; /* 16px */
  line-height: 1.5rem; /* 24px */
  font-weight: 400;
}

.input-text:focus {
  border: 1px solid var(--color-text-title) !important;
}

.progress-loading {
  width: 100%;
  height: 5px;
  border-radius: 30px;
  background: rgba(0, 0, 0, 0.25);
  box-shadow:
    inset 0 1px 2px rgba(0, 0, 0, 0.25),
    0 1px rgba(255, 255, 255, 0.08);
}

.progress-loading-bar {
  height: 5px;
  border-radius: 30px;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.3),
    rgba(255, 255, 255, 0.05)
  );
  transition: 0.4s linear;
  transition-property: width, background-color;
}

.progress-moved .progress-loading-bar {
  width: 100%;
  background-color: #0acf83;
}

.icon-valid svg path[stroke] {
  stroke: var(--color-bd-input-valid) !important;
}

@keyframes progressAnimation {
  0% {
    width: 0%;
    background-color: #0acf83;
  }
  100% {
    width: 100%;
    background-color: #0acf83;
  }
}

.watch-list li .action {
  display: none;
}

.watch-list li:hover .action {
  display: flex;
}

/* asset */

.card-asset {
  height: 100%;
  padding: 8px 8px 12px 12px;
  border-radius: 16px;
  background: var(--color-surface-soild-tertiary);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-asset path {
  border-radius: 16px;
}

/* portfolio  */

.bg-text {
  background: linear-gradient(
    181.59deg,
    #02e56a 22.97%,
    rgba(2, 229, 106, 0.493411) 42.85%,
    rgba(2, 229, 106, 0) 79.31%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.card-portfolio {
  height: 72px;
  background-color: var(--color-surface-soild-tertiary);
  padding: 4px 12px;
  border-radius: 16px;
  position: relative;
}

.card-portfolio svg.r,
.card-portfolio svg.f {
  transform: scaleY(-1);
}

.card-portfolio svg.f path:not([stroke]) {
  fill: var(--color-text-floor);
}
.card-portfolio svg.c path:not([stroke]) {
  fill: var(--color-text-ceil);
}
.card-portfolio svg.y path:not([stroke]) {
  fill: var(--color-text-ref);
}
.card-portfolio svg.g path:not([stroke]) {
  fill: var(--color-text-up);
}
.card-portfolio svg.r path:not([stroke]) {
  fill: var(--color-text-down);
}

.card-portfolio .card-hover {
  display: none;
  height: 100%;
  width: 100%;
  padding: 4px 12px;
  background-color: var(--color-text-inverse);
  border-radius: 16px;
  position: absolute;
  top: 0;
  left: 0;
}

.card-portfolio:hover .card-hover {
  display: flex;
}

.bd-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--color-border-strong);
  border-radius: 100%;
}

/* invest */
.invest .active {
  width: 100%;
  background:
    linear-gradient(#3c3c3c 0 0) padding-box,
    linear-gradient(90deg, #454545 75%, #02e56a 100%) border-box;
  border: 1px solid transparent;
  border-radius: 16px;
  display: inline-block;
  position: relative;
}

.invest .active::before {
  content: '';
  height: 40px;
  width: 40px;
  background-image: url('assets/img/icon/arrow-right.svg');
  position: absolute;
  top: 0;
  right: -8px;
}

.cus-dropdown .box-detail {
  visibility: hidden;
}

.cus-dropdown:hover .box-detail {
  visibility: visible;
}

.speech::after {
  display: block;
  width: 0;
  content: '';
  border: 15px solid transparent;
}

.speech.up::after {
  border-bottom-color: var(--color-natural-9);
  border-top: 0;
}

.speech::after {
  position: absolute;
}
.speech.up::after {
  left: -20px;
  top: calc(50% - 33px);
  transform: rotate(-90deg);
}
.speech-t::after {
  display: block;
  width: 0;
  content: '';
  border: 15px solid transparent;
}

.speech-t.up::after {
  border-bottom-color: var(--color-natural-9);
  border-top: 0;
}

.speech-t::after {
  position: absolute;
}
.speech-t.up::after {
  left: calc(50% - 28px);
  bottom: -15px;
  transform: rotate(-180deg);
}

.note {
  display: inline-block;
  margin: 0 12px 0px 0;
  border-radius: 10px;
  width: 14px;
  height: 6px;
}

/* textarea */

textarea::-webkit-scrollbar {
  width: 6px;
  margin-right: 12px;
}

textarea::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 6px;
}

.orderStt_PWX {
  border-color: var(--color-text-red);
  color: var(--color-text-red);
}

.orderStt_PW,
.orderStt_P {
  border-color: var(--color-text-warning);
  color: var(--color-text-warning);
}
.orderStt_PM,
.orderStt_PCM {
  color: var(--color-text-up);
  border-color: var(--color-bd-up);
}
.orderStt_PX,
.orderStt_PCX,
.orderStt_PCMX .orderStt_PMX,
.orderStt_PMCX {
  color: var(--color-text-down);
  border-color: var(--color-bd-down);
}

tr.row-editable td {
  background-color: var(--color-surface-soild-tertiary) !important;
}

tr.row-editable td:nth-child(1) {
  border-radius: 16px 0 0 16px;
}

tr.row-editable td:last-of-type {
  border-radius: 0 16px 0 0;
}

.ic-warn path {
  fill: var(--color-text-warning);
}

.form-cash-transfer {
  opacity: 0.5;
}
.form-cash-transfer.transfer-active {
  opacity: 1;
  background-color: var(--color-surface-soild-secondary);
  /* background-image: url(../img/logo/logo-cash.svg); */
  background-position: bottom right;
  background-repeat: no-repeat;
  border: 1px solid transparent;
  border-image-source: linear-gradient(
    180deg,
    #1c1c1c 0%,
    #01a34b 32%,
    #1c1c1c 100%
  );
  border-image-slice: 10;
}

/* switch toggle  */
.switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #262626;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: '';
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #02e56a;
}

input:focus + .slider {
  box-shadow: 0 0 1px #02e56a;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 20px;
}

.slider.round:before {
  border-radius: 50%;
}

.slick-dots {
  bottom: -18px;
}

.slick-dots li {
  height: 8px;
  width: 8px;
}
.slick-dots li.slick-active {
  width: 20px;
}
.slick-dots li button {
  padding: 0;
  height: 8px;
  width: 8px;
  border: 0.5px solid #ffffff;
  border-radius: 50%;
}

.slick-dots li button:before {
  content: '';
  height: 8px;
}

.slick-dots li.slick-active button {
  background-color: #ffffff;
  border-radius: 4px;
  width: 20px;
}

.right svg.ic-register path {
  stroke: var(--color-text-active);
}

.icon-edt-gray path {
  stroke: var(--color-icon-gray, #a3a3a3);
}
.right svg.ic-register.active path {
  stroke: var(--color-text-title);
}

.bg-trading-B {
  background: linear-gradient(
    360deg,
    rgba(48, 48, 48, 0.05) 0%,
    rgba(2, 229, 106, 0.32) 100%
  );
}

.bg-trading-S {
  background: linear-gradient(
    360deg,
    rgba(48, 48, 48, 0.05) 0%,
    rgba(242, 54, 69, 0.32) 100%
  );

  border-image-source: linear-gradient(
    360deg,
    #262626 40%,
    rgba(242, 54, 69, 0) 100%
  );
}

.bg-ac-type-stock-b {
  background: linear-gradient(94.67deg, #00e254 50%, #f19f00 100%);
}

.bg-ac-type-stock-s {
  background: linear-gradient(94.67deg, #f23645 50%, #ff76e1 100%);
}

.ic-edit-green path {
  fill: var(--color-icon-oncolor-green) !important;
}

.cus-detail-total {
  position: sticky;
  top: 36px;
  background-color: var(--color-surface-soild-secondary);
}

.trapezium {
  border-bottom: 100px solid #333;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  height: 0;
  width: 100px;
}

.input-number-cus::-webkit-inner-spin-button,
.input-number-cus::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.loader::after {
  content: '';
  position: absolute;
  transform: translate(-50%, -50%);
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 3px solid var(--color-text-title);
  border-top-color: var(--color-surface-soild-secondary);
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

.video-container {
  position: relative;
  width: 100%; /* Đặt chiều rộng mong muốn */
  height: 100%; /* Đặt chiều cao mong muốn */
  overflow: hidden; /* Ẩn phần video vượt quá kích thước container */
}

.video-container-face {
  position: relative;
  width: 100%; /* Đặt chiều rộng mong muốn */
  height: 100%; /* Đặt chiều cao mong muốn */
  overflow: hidden; /* Ẩn phần video vượt quá kích thước container */
}

.video-container video {
  width: 100%; /* Đặt chiều rộng video phù hợp với container */
  height: 100%; /* Đặt chiều cao video phù hợp với container */
  object-fit: cover; /* Đảm bảo video bao phủ toàn bộ container */
  -webkit-appearance: none; /* Ẩn thanh điều khiển trên iOS */
  appearance: none; /* Ẩn thanh điều khiển trên các trình duyệt khác */
}

.video-container-face video {
  width: 100%; /* Đặt chiều rộng video phù hợp với container */
  height: 100%; /* Đặt chiều cao video phù hợp với container */
  object-fit: cover; /* Đảm bảo video bao phủ toàn bộ container */
  -webkit-appearance: none; /* Ẩn thanh điều khiển trên iOS */
  appearance: none; /* Ẩn thanh điều khiển trên các trình duyệt khác */
}

.table-scrollable-loading-sl .scrollbar-container {
  height: calc(100vh - 500px);
}

.table-scrollable-loading-his-gdcp .scrollbar-container {
  height: calc(100vh - 307px);
}

.tooltip-cus {
  position: relative;
  display: inline-block;
}

.tooltip-cus .tooltiptext {
  visibility: hidden;
  width: 350px;
  color: var(--color-text-white);
  background-color: var(--color-bg-tooltip);
  border-radius: 4px;
  font-size: 0.875rem; /* 14px */
  line-height: 1.25rem; /* 20px */
  z-index: 1000;
  text-align: left;
  border-radius: 6px;
  padding: 12px;
  position: absolute;
  bottom: 125%;
  left: 50%;
  margin-left: -173px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-cus .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip-cus:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

#global {
  position: absolute;
  z-index: 9999;
}

.cus-order tr {
  border-bottom: 1px solid var(--color-border-weak);
}

.img-gray {
  filter: grayscale(0);
  -webkit-filter: grayscale(0);
}

.rc-slider-rail {
  background-color: #3b3b3b;
}

.rc-slider-dot {
  width: 1px;
  background-color: #3b3b3b;
  border: 1px solid #3b3b3b;
  border-radius: 0;
}

.rc-slider-dot-active {
  border-color: #02e56a;
}

.rc-slider-handle {
  border: solid 4px #02e56a;
}

.rc-slider-track,
.rc-slider-tracks {
  background-color: #02e56a;
}

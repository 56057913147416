@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-text-up: #00ff00;
    --color-text-down: #ff3737;
    --color-text-ref: #ffd900;
    --color-text-ceil: #ff25ff;
    --color-text-floor: #00b2ff;

    --color-green-bg: 27, 186, 100;
    --color-red-bg: 211, 47, 47;
    --color-ref-bg: 253, 255, 18;

    --color-text-1: #16181c;
    --color-text-2: #636870;
    --color-text-3: rgba(255, 255, 255, 0.79);
    --color-text-4: rgba(255, 255, 255, 0.88);
    --color-text-6: #ec5e0f;

    --color-stt-1: #00ba34;
    --color-stt-2: #e8b738;
    --color-stt-3: #d42229;
    --color-hyperlink: rgba(0, 183, 232, 1);

    --color-text-white: #ffffff;
    --color-text-dark: #000;
    --color-text-svg-act: #ffffff;

    --color-text-muted: rgba(255, 255, 255, 0.32);
    --color-text-placeholder: rgba(255, 255, 255, 0.82);
    --color-text-unit: rgba(255, 255, 255, 0.64);

    --color-text-vendor: #bbc7da;
    --color-text-half-white: #818181;
    --color-text-a: #0056b3;

    --color-natural-1: rgba(31, 31, 31, 1);
    --color-natural-2: rgba(41, 41, 41, 1);
    --color-natural-3: rgba(31, 31, 31, 1);
    --color-natural-4: #00ff00;
    --color-natural-5: rgba(0, 0, 0, 0.32);
    --color-natural-6: rgba(255, 255, 255, 0.1);
    --color-natural-7: rgba(99, 99, 99, 1);
    --color-natural-8: #00ff731a;
    --color-natural-9: #1f1f1f;
    --color-natural-10: #dddddd;
    --color-natural-11: #0acf83;
    --color-natural-12: #00000080;
    --color-natural-13: #3b3b3b1a;
    --color-vendor: #3d4044;
    --color-vendor-active: #00ff00;
    --color-vendor-hover: #00c354;
    --color-vendor-from: rgba(110, 198, 68, 1);
    --color-vendor-to: rgba(39, 142, 52, 1);
    --color-nav: #1c192c;
    --color-thumb: #f9fafc;

    --color-button-success: #18c873;
    --color-button-accent: #0fa779;
    --color-button-accent-hover: #0fa779;
    --color-button-trade: #454c59;

    --color-border: rgba(41, 41, 41, 1);
    --color-border-1: rgba(41, 41, 41, 1);
    --color-border-2: rgba(70, 70, 70, 1);
    --color-border-3: rgba(52, 200, 90, 1);
    --color-border-4: rgba(8, 87, 57, 1);
    --color-border-5: #393939;
    --color-border-6: #181a20;
    --color-border-7: #00ff731a;
    --color-border-tooltip: #d2d6e3;
    --color-border-trade: #4e4d62;
    --color-border-tbl: #3b3b3b;
    --color-border-td: rgba(51, 51, 51, 1);
    --color-border-modal: #454545;

    --color-bg: rgba(53, 54, 65, 1);
    --color-bg-ref: rgba(244, 159, 59, 1);
    --color-bg-ceil: rgba(255, 0, 255, 1);
    --color-bg-floor: rgba(0, 155, 222, 1);
    --color-bg-nav: rgba(34, 33, 31, 1);
    --color-bg-card2: rgba(19, 19, 19, 1);
    --color-bg-badge-up: #01602d;
    --color-bg-badge-down: #472124;

    --color-bg-tbl-thead: #2626264d;
    --color-bg-tbl-theadPT: #1e2329;
    --color-bg-tbl-th: #242930;
    --color-bg-tbl-td: rgba(16, 16, 14, 1);
    --color-bg-tbl-td2: #1f1f1f;
    --color-bg-tbl-td-hover: rgba(54, 57, 64, 1);
    --color-bg-tbl-light: #1e2329;
    --color-bg-tbl-even: #0d0d0d;

    --color-bg-tooltip: #242930;
    --color-bg-modal: #2d2d40;
    --color-bg-tbl-no-border: #181625;
    --color-bg-total: #353342;
    --color-bg-disable: #2f2d37;
    --color-bg-buy: #0fc786;
    --color-bg-sell: #f5465c;
    --color-bg-trade: #020210;

    --color-bg-tbl-analysis-th: #353342;
    --color-bg-tbl-analysis-td: #1c192c;
    --color-bg-tbl-analysis-light: #373545;
    --color-bg-tbl-analysis-odd: #181625;
    --color-bg-li-light: #0b1c34;
    --color-bg-button-news: #e7f7f2;

    --bg_card_chart: #e0e3eb;

    --color-text-btn-login: #ffffff;
    --color-text-red: #ff3737;
    --color-text-nav-left-active: #34c85a;

    --color-bg-btn-login: #00923f;
    --color-bg-nav-left-sm: #2f2e43;

    --color-bg-panel-trade: #181a20;
    --color-bg-panel-trade-asset: rgba(40, 43, 47, 0.9);

    --color-bd-hr: #d9d9d9;
    --color-bd-nav-left-active: #0fbc2c;
    --color-bd-nav-active: #d9d9d9;

    --color-shadow-nav-left: #55555540;

    --color-img-header: #d9d9d9;
    --color-radio-header: #ffffff;
    --color-img-tbl: #ffffff;

    /* Them moi */
    --color-mint-500: #02aa82;
    --color-white-alpha-300: rgba(255, 255, 255, 0.17);
    --color-neutral-400: #454545;
    --color-white-alpha-200: rgba(255, 255, 255, 0.11);
    --color-grayscale-800: #2e2e2e;
    --color-grayscale-600: #737373;

    --color-text-title: #ffffff;
    --color-text-body: rgba(255, 255, 255, 0.79);
    --color-text-subdued: rgba(255, 255, 255, 0.55);
    --color-text-oncolor-green: #00ff00;
    --color-text-oncolor-blue: #00b2ff;
    --color-text-oncolor-mint: #02aa82;
    --color-text-disable: rgba(255, 255, 255, 0.37);
    --color-text-oncolor-yellow: #ffd900;

    --color-surface-soild-green: #00ff00;
    --color-surface-soild-secondary: #1e2329;
    --color-surface-soild-primary: #181a20;
    --color-surface-soild-tertiary: #242930;
    --color-surface-inverse-black: rgba(0, 0, 0, 0.06);
    --color-surface-inverse-white: rgba(255, 255, 255, 0.06);
    --color-surface-inverse-blue: #004b6b;

    --color-border-strong: #3b3b3b;

    --color-icon-oncolor-green: #00ff00;
    --color-icon-white: #ffffff;
    --color-icon-oncolor-yellow: #ffd900;
    --color-icon-oncolor-blue: #00b2ff;
    --color-icon-gray: #a3a3a3;
    --color-icon-violet: #ff25ff;

    --color-black-alpha-200: rgba(0, 0, 0, 0.11);
    --color-mint-900: #014737;

    --color-chart-green: #00ff00;

    --color-border-weak: #454545;
    --color-border-white: #ffffff;
    --color-border-green: #00ff00;
    --color-background-primary: #111111;

    --color-text-nav: #ffffff;
    --color-text-nav-disable: rgba(255, 255, 255, 0.37);
    --color-text-nav-active: #00ff00;
    --color-text-active: #00ff00;
    --color-text-body: rgba(255, 255, 255, 0.788);
    --color-text-a: #00b2ff;
    --color-text-inverse: rgba(0, 0, 0, 0.788);
    --color-text-warning: #e57402;

    --color-bg-input: #1e2329;
    --color-bg-divider: #454545;
    --color-bg-red: #ff3737;
    --color-bg-red2: #851e26;
    --color-bg-account-header: #ffffff0f;
    --color-bg-icon-header: #1e2329;
    --color-bg-header: #111111;
    --color-bg-nav-left: #111111;
    --color-bg-tab: #181a20;
    --color-bg-body: #111111;
    --color-bg-main: #181a20;
    --color-bg-tab-active: #242930;
    --color-bg-icon: #434343;
    --color-bg-card: #1e2329;
    --color-bg-dropdown: #181a20;
    --color-bg-primary: #181a20;
    --color-bg-li: rgba(255, 255, 255, 0.059);
    --color-bg-modal2: #181a20;
    --color-bg-white: #ffffff;
    --color-bg-otp: #242930;
    --color-bg-green: #01602d;
    --color-bg-soild-green: #00ff00;
    --color-bg-submit-disable: #121212;
    --color-bg-index: #111111;
    --color-bg-nav-active: #00ff00;
    --color-bg-up: rgba(1, 96, 45, 0.2);
    --color-bg-down: #472124;
    --color-icon-oncolor-purple: #7b57ff;
    --color-black-alpha-300: rgba(0, 0, 0, 0.169);
    --color-white-alpha-100: #ffffff0f;

    --color-white-alpha-300: #ffffff2b;
    --color-black-alpha-400: #0000005e;

    --color-surface-inverse-green: #005022;
    --color-surface-inverse-red: #472124;
    --color-surface-soild-green1: #00bf52;
    --color-surface-soild-quatinary: #2b3139;

    --color-bg-coin: rgba(59, 59, 59, 0.102);
    --color-bd-dropdown: #454545;
    --color-bd-input: #ffffff;
    --color-bd-input-valid: #ff3737;
    --color-bd-up: #01602d;
    --color-bd-down: #472124;
    --color-bd-grayscale: #525252;
    --color-bd-yellow: #494721;
  }

  .light-mode {
  }
}

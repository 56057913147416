.nav-account {
  /* background: var(--color-bg-nav-left); */
}

.nav-account.nav_hover {
  background: var(--color-bg-nav-left);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  width: 180px;
  transition:
    visibility 0s,
    opacity 0.5s ease;
}

.nav-account ul li {
  margin-bottom: 4px;
}

.nav-account ul li a {
  /* z-index: inherit; */
  padding: 0 8px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: var(--color-text-nav);
  height: 36px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  box-sizing: border-box;
  background-clip: padding-box !important;
}

.nav-account ul li a.active {
  color: var(--color-text-nav-active);
  border-radius: 1000px;
  /* background-color: var(--color-bg-primary); */
  background: #181a2066;
}

/* icon */
.nav-account ul li a:not(.active) svg #iconNormal {
  display: block;
}
.nav-account ul li a:not(.active) svg #iconACtive {
  display: none;
}

.nav-account ul li a:hover svg #iconNormal,
.nav-account ul li a.active svg #iconNormal {
  display: none !important;
}
.nav-account ul li a:hover svg #iconACtive,
.nav-account ul li a.active svg #iconACtive {
  display: block !important;
}

/* nav-disabled */

.nav-account ul li a.disabled {
  color: var(--color-text-nav-disable);
}

.nav-account ul li a.disabled svg path:not([stroke]) {
  fill: var(--color-text-nav-disable);
}

.nav-account ul li a.disabled:hover,
.nav-account ul li a.disabled:active {
  border: none;
}

.nav-account ul li a.disabled:hover svg path:not([stroke]),
.nav-account ul li a.disabled.active svg path:not([stroke]) {
  fill: var(--color-text-nav-disable);
}

.nav-account ul li .ps__rail-y {
  right: 10px !important;
}

/* nav_minimize */

.nav-account.nav_minimize ul li a {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
}

.nav-account.nav_minimize ul li a:hover,
.nav-account.nav_minimize ul li a.active {
  border: none;
  color: var(--color-text-nav-active);
}

.nav-account.nav_minimize ul li:hover ul.sub-menu {
  display: block;
}

.nav-account.nav_minimize ul li ul.sub-menu {
  position: absolute;
  top: 0;
  left: 58px;
  width: 220px !important;
  -webkit-padding-start: 0;
  padding-inline-start: 0;
  -webkit-margin-before: 0;
  margin-block-start: 0;
  z-index: 5;
  display: none;
}

.nav-account.nav_minimize ul li ul.sub-menu li {
  justify-content: flex-start;
  border: 2px solid #2e4642;
  border-width: 0 0 1px 5px;
}

.nav-account .divider {
  width: 100%;
  height: 1px;
  margin: 8px 0;
  background: var(--color-bg-divider);
}

.navbar-priceboard .dropdown {
  position: relative;
}

.navbar-priceboard .nav-item {
  height: 32px;
  border-radius: 9999px;
  padding: 4px 12px;
  font-size: 14px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  font-weight: 500;
}

.navbar-priceboard .nav-link {
  color: var(--color-text-subdued);
  cursor: pointer;
  display: flex;
  align-items: center;
}

.navbar-priceboard .nav-link svg {
  margin-left: 8px;
}

.navbar-priceboard .nav-item.active {
  background-color: var(--color-bg-nav-active);
}

.navbar-priceboard .nav-item.active a.dropdown-toggle,
.navbar-priceboard .nav-item.active a.nav-cw {
  font-style: normal;
  color: var(--color-mint-900) !important;
}

.navbar-priceboard .nav-item.active .nav-link svg path[stroke] {
  stroke: var(--color-mint-900) !important;
}

.navbar-priceboard .dropdown:hover .dropdown-menu.position {
  display: block;
}

.navbar-priceboard
  .dropdown-menu.position
  .nav-link:hover
  .dropdown-menu.position-more {
  display: block;
}

.navbar-priceboard .dropdown-menu {
  background-color: var(--color-bg-dropdown);
  border: 1px solid var(--color-bd-dropdown);

  z-index: 1000;
  display: none;
  float: left;
  min-width: 243px;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  list-style: none;
  background-clip: padding-box;
  border-radius: 16px;
  padding: 12px;
}

.navbar-priceboard .dropdown-menu.position {
  position: absolute;
  top: 32px;
  left: 0;
}

.navbar-priceboard .dropdown-menu.position-more {
  position: absolute;
  top: 0px;
  right: -246px;
}

.navbar-priceboard .dropdown-menu:hover {
  display: block;
}

.navbar-priceboard .dropdown-menu .nav-link {
  /* margin-bottom: 8px; */
  padding: 8px 12px;
  color: var(--color-text-title) !important;
  background-color: var(--color-bg-li);
  border-radius: 9999px;
}

.navbar-priceboard .dropdown-menu .nav-link:hover {
  background: var(--color-bg-nav-active);
  color: var(--color-mint-900) !important;
}

.navbar-priceboard .nav-item-drop.active {
  background-color: var(--color-natural-2);
  color: var(--color-text-white) !important;
}

.navbar-priceboard .nav-item-drop.active .nav-link {
  background-color: var(--color-bg-primary);
  color: var(--color-text-title) !important;
}

.navbar-priceboard .nav-item-drop .nav-link {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.btn-drop {
  font-size: 12px;
  padding: 1px 0.5rem !important;
  height: 22px;
  color: #fff;
  border-radius: 2px;
}

.btn-drop:hover {
  background: var(--color-natural-4);
  color: var(--color-text-title);
}

.field-change-catalog {
  width: 100%;
  font-size: 14px;
  padding: 0px 12px;
  margin-right: 8px;
  background: var(--color-bg-dropdown);
  border: 1px solid var(--color-bd-input);
  border-radius: 9999px;
}

.field-change-catalog:focus,
.field-change-catalog:active {
  border: 1px solid var(--color-bd-input);
  box-shadow: none;
  outline: none;
}

/* nav trade */

.nav-trade {
  height: 26px;
  padding-top: 8px;
  border-top: 0.5px solid var(--color-border-3);
  color: var(--color-thumb);
  display: flex;
  align-items: center;
  justify-content: end;
  font-size: 13px;
}

svg.text-skin-ref path {
  fill: var(--color-text-ref);
}

.nav-dropdown-action {
  right: 8px;
  top: 34px;
}

.nav-dropdown-action ul li {
  border-radius: 4px;
  padding: 8px;
  height: 36px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--text-skin-white);
}
